import Box from '@mui/material/Box';
import React, { ChangeEvent, ReactNode, useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { CircularProgress, debounce, SxProps } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';

import { SIZES_NUMBER_TINY_SMALL } from '../../constants/sizes';
import { GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import ItemsTableList from './ItemsTableList';
import { TableHeaderModel, ItemCardModel } from '../../models/TableModel';
import FlexRowCenter from '../utils/flex/FlexRowCenter';
import { ItemTableModel } from '../../models/ItemProps';
import ListActionsHeader from './ListActionsHeader';
import FlexRowEnd from '../utils/flex/FlexRowEnd';

interface ItemsListProps {
  pageSubtitle?: string;
  tableData: any;
  selectedItem?: any;
  headerCells: TableHeaderModel[];
  tableDataForCardView?: ItemCardModel[];
  hasMultipleSelection?: boolean;
  loading?: boolean;
  onRowClick?: (item: ItemTableModel) => void;
  onEdit?: (id: number) => void;
  onDelete?: () => void;
  displaySearch?: boolean;
  searchInputStyles?: SxProps;
  sxHeader?: SxProps;
  displayListViewType?: boolean;
  hasFilters?: boolean;
  componentType?: string;
  hasRefresh?: boolean;
  isServiceRequestsList?: boolean;
  isRulesConfigList?: boolean;
  isImageSearchHistoryList?: boolean;
  isSearchFoundList?: boolean;
  pageTitle?: string;
  customRight?: ReactNode;
  additionalRow?: ReactNode;
  isGateway?: boolean;
  refreshData?: () => void;
  hasMore?: boolean;
  loadMore?: () => void;
  isConfiguration?: boolean;
  isHistoryTab?: boolean;
  clearFilters?: () => void;
  height?: string;
  isConfigurationMaps?: boolean;
  onDownloadClick?: () => void;
  allowBackgroundColor?: boolean;
  isImports?: boolean;
  isExport?: boolean;
  widthPagination?: boolean;
  sxCell?: SxProps;
  page?: number;
  totalPages?: number;
  setPage?: (a1: number) => void;
}

const ItemsList = ({
  pageSubtitle,
  headerCells,
  tableData,
  tableDataForCardView,
  hasMultipleSelection,
  loading,
  isHistoryTab = false,
  onRowClick,
  onEdit,
  onDelete,
  displaySearch = true,
  searchInputStyles,
  displayListViewType = true,
  hasFilters,
  componentType,
  isServiceRequestsList = false,
  isRulesConfigList = false,
  isImageSearchHistoryList = false,
  isSearchFoundList = false,
  hasRefresh,
  pageTitle,
  customRight,
  isGateway = false,
  refreshData,
  hasMore,
  loadMore,
  isConfiguration,
  height,
  isConfigurationMaps = false,
  onDownloadClick,
  allowBackgroundColor = false,
  isImports = false,
  isExport = false,
  widthPagination = false,
  sxHeader,
  sxCell,
  selectedItem,
  additionalRow,
  page = 1,
  setPage,
  totalPages,
  clearFilters,
}: ItemsListProps) => {
  const {
    filterStore: { setSearchValueFilter, searchValueFilter },
  } = useStore();

  const [searchValue, setSearchValue] = useState<string>(searchValueFilter);
  const [filteredTableData, setFilteredTableData] = useState(tableData);

  const isMobile = useMediaQuery({ maxWidth: SIZES_NUMBER_TINY_SMALL });

  useEffect(() => {
    setSearchValueFilter('');
    setSearchValue('');
  }, []);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (searchValueFilter === '') {
      setSearchValue('');
    }
  }, [searchValueFilter]);

  const debouncedSearchValueFilter = useCallback(
    debounce((value: string) => setSearchValueFilter(value), 300),
    [setSearchValueFilter]
  );
  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
    debouncedSearchValueFilter(value);
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    if (setPage) setPage(page);
  };

  return (
    <Box sx={{ height: '100%' }}>
      {(!isServiceRequestsList || isMobile) && (
        <ListActionsHeader
          pageSubtitle={pageSubtitle}
          searchValue={searchValue}
          currentSelection={'table_view'}
          changeCurrentSelection={() => { }}
          onInputChange={onChange}
          hasFilters={hasFilters}
          componentType={componentType}
          displaySearch={displaySearch}
          isRulesConfigList={isRulesConfigList}
          searchInputStyles={searchInputStyles}
          displayListViewType={displayListViewType}
          hasRefresh={hasRefresh}
          tableTitle={pageTitle}
          customRight={customRight}
          isServiceRequestListMobile={!!isServiceRequestsList && isMobile}
          refreshData={refreshData}
          isConfiguration={isConfiguration}
          isSearchFoundList={isSearchFoundList}
          isGateway={isGateway}
          selectedItem={selectedItem}
          additionalRow={additionalRow}
          clearFilters={clearFilters}
        />
      )}

      {loading ? (
        <FlexRowCenter>
          <CircularProgress />
        </FlexRowCenter>
      ) : (
        <ItemsTableList
          header={headerCells}
          items={filteredTableData} // pagination added
          hasMultipleSelection={hasMultipleSelection}
          onRowClick={onRowClick}
          gateway={isGateway}
          hasMore={hasMore}
          loadMore={loadMore}
          loading={loading}
          allowBackgroundColor={allowBackgroundColor}
          leftAlign={isGateway}
          isImports={isImports}
          isExport={isExport}
          sxHeader={sxHeader}
          sxCell={sxCell}
          selectedItem={selectedItem}
        />
      )}
      {!!(widthPagination && filteredTableData?.length) && (
        <FlexRowEnd sx={{ alignItems: 'center', height: '42px', paddingRight: '16px' }}>
          <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
            Page {page} of {totalPages}
          </Typography>
          <Pagination
            count={totalPages} //pagination added
            page={page}
            showFirstButton
            showLastButton
            onChange={handleChangePage}
            renderItem={item => {
              if (item.type === 'page' || item.type.includes('-ellipsis')) {
                return null;
              }
              return <PaginationItem {...item} />;
            }}
          />
        </FlexRowEnd>
      )}
    </Box>
  );
};

export default observer(ItemsList);

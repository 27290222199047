import IosShareIcon from '@mui/icons-material/IosShare';
import { CircularProgress, SxProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import { COLOR_BORDER_SECONDARY, GRAY_COLORS } from '../../constants/colors';
import { useStore } from '../../hooks/useStore';

import { IconButtonWrapper } from '../buttons/ViewModeButtons';
import Flex from '../utils/flex/Flex';
import { getExcelData } from '../adminPortal/auditLogs/excelData';

export interface ExportExcelProps {
  dataToExportExcel: Array<any>;
  fileName: string;
  skipHeader?: boolean;
  sx?: SxProps;
  exportAdditionalData?: () => Promise<any>;
}

const ExportExcel = ({
  dataToExportExcel,
  fileName,
  sx,
  skipHeader = false,
  exportAdditionalData,
}: ExportExcelProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const downloadExcel = async () => {
    setIsLoading(true);

    let dataToExport = dataToExportExcel;
    if (exportAdditionalData) {
      const newData = await exportAdditionalData();

      dataToExport = getExcelData({ i18n, items: newData });
    }

    // Truncate large string values to prevent Excel's 32,767 character limit error
    const processedData = dataToExport.map(row => {
      const processedRow = { ...row };
      Object.keys(processedRow).forEach(key => {
        if (typeof processedRow[key] === 'string' && processedRow[key].length > 32000) {
          processedRow[key] = processedRow[key].substring(0, 32000) + '... (truncated)';
        }
      });
      return processedRow;
    });

    try {
      const worksheet = XLSX.utils.json_to_sheet(processedData, { skipHeader });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      // You might want to show an error notification to the user here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex sx={sx}>
      <IconButtonWrapper
        active={true}
        onClick={isLoading ? () => { } : () => downloadExcel()}
        sx={{
          height: '40px',
          padding: '11px 16px',
          border: `1px solid ${COLOR_BORDER_SECONDARY}`,
          background: 'transparent',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Flex sx={{ alignItems: 'center', gap: '8px' }}>
            <IosShareIcon sx={{ color: GRAY_COLORS.GRAY_9, width: '18px', height: '18px' }} />
            <Typography
              variant={'body2'}
              fontSize={'14px'}
              color={GRAY_COLORS.GRAY_9}
              fontWeight={'500'}
              whiteSpace={'nowrap'}
            >
              {i18n.t('exportXLS.button.action')}
            </Typography>
          </Flex>
        )}
      </IconButtonWrapper>
    </Flex>
  );
};

export default ExportExcel;
